//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'

import ModalDialog from './ModalDialog.vue'
import modal_mixin from './modal-mixin'

import {mapActions, mapGetters} from 'vuex'
import { USERDATA } from "../../store/modulesNames"
import { 
    ACT_VOLUME_NOTIFICATION, 
    ACT_VOLUME_RING, 
    ACT_VOLUME_RADIO, 
    ACT_UPDATE_SOUND, 
} from "../../store/actionsTypes"
import { 
    GET_NOTIFICATION_SOUND, 
    GET_VOLUME_NOTIFICATION, 
    GET_RING_SOUND, 
    GET_VOLUME_RING,
    GET_SECOND_CALL_SOUND,
    GET_HOLD_SOUND,
    GET_RADIO_SOUND,
    GET_VOLUME_RADIO, 
} from "../../store/gettersTypes"

import CustomDropDown from '../custom/CustomDropDown.vue'
import VueSlider from 'vue-range-slider'
import Loader from '../main/body/MainContentLoader.vue'

import { setIDB, keysIDB, delIDB } from '../../../ext/indexedDB'
import { trimAudio, audioBufferToWav, wavToMp3Blob } from '../../../ext/sound-utils'

import { SOUND_TYPES, SOUNDS } from '../../constants'
const NOTIF_LEN = 5, RING_LEN = 15, SECOND_CALL_LEN = 5, HOLD_LEN = 15, RADIO_LEN = 3

export default {
    name: "SoundSettings",
    components: { ModalDialog, VueSlider, CustomDropDown, Loader },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.40,
                pivotX: 0.73,
                width: 480,
                height: 'auto'
            },
            sections: [
                {value: SOUND_TYPES.NOTIFICATION, name: this.$t('settings.notifications'), selected: true},
                {value: SOUND_TYPES.RING, name: this.$t('calls'), selected: false},
                {value: SOUND_TYPES.RADIO_SOUND, name: this.$t('radio'), selected: false},
            ],
            selected_section: '',
            playNotificationsound: false,
            playRingsound: false,
            playSecondcallsound: false,
            playHoldsound: false,
            playRadiosound: false,
            sliderDisabled: false,
            notificationSliderValue: 0,
            callSliderValue: 0,
            radioSliderValue: 0,
            currentSoundType: '',
            currentSoundName: '',
            sounds: {},
            updatingSound: '',
            DEFAULT_NOTIFICATION_SOUND: SOUNDS[SOUND_TYPES.NOTIFICATION],
            DEFAULT_RING_SOUND: SOUNDS[SOUND_TYPES.RING],
            DEFAULT_SECOND_CALL_SOUND: SOUNDS[SOUND_TYPES.SECOND_CALL],
            DEFAULT_HOLD_SOUND: SOUNDS[SOUND_TYPES.HOLD],
            DEFAULT_RADIO_SOUND: SOUNDS[SOUND_TYPES.RADIO_SOUND],
        }
    },
    async created() {
        if (!this.SOUND_TYPES) this.SOUND_TYPES = SOUND_TYPES
        if (!this.SOUNDS) this.SOUNDS = SOUNDS
        await this.setSoundsObj()
    },
    mounted() {
        this.selected_section = SOUND_TYPES.NOTIFICATION
        this.notificationSliderValue = this.notificationsVolume * 100
        this.callSliderValue = this.ringVolume * 100
        this.radioSliderValue = this.radioVolume * 100
    },
    computed: {
        title() {
           return this.$t('settings.sounds')
        },
        notificationSoundSrc() {
            return this[GET_NOTIFICATION_SOUND]
        },
        notificationsVolume() {
            return this[GET_VOLUME_NOTIFICATION] 
        },
        ringSoundSrc() {
            return this[GET_RING_SOUND]
        },
        ringVolume() {
            return this[GET_VOLUME_RING] 
        },
        secondCallSoundSrc() {
            return this[GET_SECOND_CALL_SOUND]
        },
        holdSoundSrc() {
            return this[GET_HOLD_SOUND]
        },
        radioSoundSrc() {
            return this[GET_RADIO_SOUND]
        },            
        radioVolume() {
            return this[GET_VOLUME_RADIO] 
        },
        ...mapGetters(USERDATA, [
            GET_NOTIFICATION_SOUND,
            GET_VOLUME_NOTIFICATION,
            GET_RING_SOUND,
            GET_VOLUME_RING,
            GET_SECOND_CALL_SOUND,
            GET_HOLD_SOUND,
            GET_RADIO_SOUND,
            GET_VOLUME_RADIO,
        ])
    },
    watch: {
        selected_section(newVal) {
            this.currentSoundType = newVal
        },
        notificationSliderValue(newVal, oldVal) {
            this.changeVolume(newVal, SOUND_TYPES.NOTIFICATION)
            if (!!oldVal) this.startPlay(SOUND_TYPES.NOTIFICATION)
            this[ACT_VOLUME_NOTIFICATION](newVal)
        },
        callSliderValue(newVal) {
            this.changeVolume(newVal, SOUND_TYPES.RING)
            this.changeVolume(newVal, SOUND_TYPES.SECOND_CALL)
            this.changeVolume(newVal, SOUND_TYPES.HOLD)
            this[ACT_VOLUME_RING](newVal)
        },
        radioSliderValue(newVal, oldVal) {
            this.changeVolume(newVal, SOUND_TYPES.RADIO_SOUND)
            if (!!oldVal) this.startPlay(SOUND_TYPES.RADIO_SOUND)
            this[ACT_VOLUME_RADIO](newVal)
        },
    },
    methods: {
        setSection(val) {
            this.selected_section = val
        },            
        changeVolume(val, refName) {
            switch (refName) {
                case SOUND_TYPES.NOTIFICATION:
                    this.notificationSliderValue = val
                    break
                case SOUND_TYPES.RING:
                // case SOUND_TYPES.SECOND_CALL:
                // case SOUND_TYPES.HOLD:
                    this.callSliderValue = val
                    break
                case SOUND_TYPES.RADIO_SOUND:
                    this.radioSliderValue = val
                    break
                default:
                    break   
            }
            setTimeout(() => {
                let audioElement = this.$refs[refName]
                if (!audioElement) return
                audioElement.volume = val / 100
            }, 300)
        },
        togglePlaySound(refName) {
            const playPropName = 'play' + refName[0].toUpperCase() + refName.slice(1)
            try {
                if(!this[playPropName]) this.startPlay(refName)
                else this.stopPlay(refName)
            } catch(e) { this.stopPlay(refName) }
        },
        startPlay(refName) {
            if (!refName) return
            const playPropName = 'play' + refName[0].toUpperCase() + refName.slice(1)
            this[playPropName] = true
            const audioElement = this.$refs[refName]
            audioElement.play()
        },
        stopPlay(refName) {
            const audioElement = this.$refs[refName]
            if (!audioElement) return
            !audioElement.paused && audioElement.pause()
            audioElement.currentTime = 0;
            const playPropName = 'play' + refName[0].toUpperCase() + refName.slice(1)
            this[playPropName] = false
        },
        selectFile(soundType) {
            this.currentSoundType = soundType
            const fileLoader = this.$refs.fileLoader
            fileLoader.value = ''
            fileLoader.accept = 'audio/*'
            fileLoader.click()
        },
        async uploadFile() {
            this.updatingSound = this.currentSoundType
            let startTime = 0, endTime = RADIO_LEN
            switch (this.currentSoundType) {
                case SOUND_TYPES.NOTIFICATION:
                    endTime = NOTIF_LEN
                    break
                    case SOUND_TYPES.RING:
                        endTime = RING_LEN
                    break
                case SOUND_TYPES.SECOND_CALL:
                    endTime = SECOND_CALL_LEN
                    break
                case SOUND_TYPES.HOLD:
                    endTime = HOLD_LEN
                    break
                // case SOUND_TYPES.RADIO:
                //     endTime = 3
                //     break
                default:
                    break
                }
            const fileLoader = this.$refs.fileLoader
            if (!fileLoader.files || !fileLoader.files.length) return
            const uploadedFile = fileLoader.files[0]
            let fileName = uploadedFile && uploadedFile.name
            let splitted = fileName && fileName.split('.').length
            if (splitted) {
                let splArr = fileName.split('.')
                splArr.pop()
                fileName = splArr.join('.')
            }
            this.currentSoundName = fileName
            const trimmedBuffer = await trimAudio(uploadedFile, startTime, endTime)
            const wav = audioBufferToWav(trimmedBuffer)
            const mp3Blob = wavToMp3Blob(wav)
            const key = await this.getKeyIDB(this.currentSoundType)
            if (key) await delIDB(key)
            setIDB(`${this.currentSoundType}:${this.currentSoundName}`, mp3Blob)
            window.URL.revokeObjectURL(mp3Blob)
            const url = window.URL.createObjectURL(mp3Blob)
            await this[ACT_UPDATE_SOUND]({[this.currentSoundType]: url })
            const soundType = this.currentSoundType
            const soundNameUrl = await this.setSoundNameUrl(soundType)
            Vue.set(this.sounds, [soundType], soundNameUrl)
            this.updatingSound = ''
        },
        async setSoundsObj() {
            Object.keys(SOUND_TYPES).forEach(async (key) => {
                const soundNameUrl = await this.setSoundNameUrl(SOUND_TYPES[key])
                Vue.set(this.sounds, [SOUND_TYPES[key]], soundNameUrl)
            })
        },
        async setSoundNameUrl(soundType = '') {
            if (!soundType) return 
            const key = await this.getKeyIDB(soundType)
            if (key) {
                const name = key.split(':')[1]
                let url
                switch (soundType) {
                    case SOUND_TYPES.NOTIFICATION:
                        url = this.notificationSoundSrc
                        break
                    case SOUND_TYPES.RING:
                        url = this.ringSoundSrc
                        break
                    case SOUND_TYPES.SECOND_CALL:
                        url = this.secondCallSoundSrc
                        break
                    case SOUND_TYPES.HOLD:
                        url = this.holdSoundSrc
                        break
                    case SOUND_TYPES.RADIO_SOUND:
                        url = this.radioSoundSrc
                        break
                    default:
                        break
                }                    
                return { name, url }
            } else return { name: this.$t('default'), url: SOUNDS[soundType] }
        },
        getSoundName(soundType = '') {
            if (!soundType) return
            const soundName = this.sounds[soundType] && this.sounds[soundType].name
            return soundName
        },
        getSoundUrl(soundType = '') {
            if (!soundType) return
            const url = this.sounds[soundType] && this.sounds[soundType].url
            return url
        },
        async getKeyIDB(startSubstr) {
            const keys = await keysIDB()
            let key = keys.find(k => k.startsWith(startSubstr))
            return key
        },
        getTitle(soundType) {
            let const_part = this.$t('sound-file-title') //'Upload file, first **** sec duration is going to be used'
            switch (soundType) {
                case SOUND_TYPES.NOTIFICATION:
                    return const_part.replace('****', NOTIF_LEN)
                case SOUND_TYPES.RING:
                    return const_part.replace('****', RING_LEN)
                case SOUND_TYPES.SECOND_CALL:
                    return const_part.replace('****', SECOND_CALL_LEN)
                case SOUND_TYPES.HOLD:
                    return const_part.replace('****', HOLD_LEN)
                default:
                    return const_part.replace('****', RADIO_LEN)
            }
        },
        async resetSound(soundType) {
            const key = await this.getKeyIDB(soundType)
            if (key) await delIDB(key)
            await this[ACT_UPDATE_SOUND]({[soundType]: SOUNDS[soundType]})
            Vue.set(this.sounds, [soundType], { name: this.$t('default'), url: SOUNDS[soundType] })
        },
        // okBtn() {
        //     this[ACT_VOLUME_NOTIFICATION](this.notificationSliderValue)
        //     this[ACT_VOLUME_RING](this.callSliderValue)
        //     this[ACT_VOLUME_RADIO](this.radioSliderValue)
        //     this.cancelBtn()
        // },
        cancelBtn() {
            this.modalClose();
        },
        ...mapActions(USERDATA, [
            ACT_VOLUME_NOTIFICATION, 
            ACT_VOLUME_RING,
            ACT_VOLUME_RADIO,
            ACT_UPDATE_SOUND,
        ]),
    },
}
