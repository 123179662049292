import proto from '../../protocol'
import store from './store'
import { i18n } from '../../../ext/i18n'
import {
    GET_ROSCHAT_SERVER,
    GET_IS_ELECTRON,
    GET_SELECTED_CHANNEL,
} from '../gettersTypes'
import {
    ACT_HANDLE_TOKENS_EVENT,
    ACT_HANDLE_CHANNEL_CHANGE_EVENT,
    ACT_SAVE_REMOTE_PROCESSING,
    ACT_TERMINATION_EVENT,
    ACT_CHANGE_CONTACT_STATUS,
    CALLS_EVENTS_CONTROLLER,
    ACT_UPDATE_TA,
    ACT_HIDE_INCOMMING_CALL,
    ACT_UPDATE_CALLS,
    ACT_UPDATE_CALL,
    ACT_HANDLE_MESSAGE_EVENT,
    ACT_HANDLE_MESSAGE_CHANGE_EVENT,
    ACT_HANDLE_MESSAGE_UPDATE_EVENT,
    ACT_HANDLE_MESSAGE_REACTION_EVENT,
    ACT_UPDATE_CHAT,
    ACT_EVENT_PHONE,
    ACT_SET_CONNECTION_STATUS,
    ACT_ALARM_SET_PROPS,
    ACT_SOCKET_RECONNECT,
    ACT_ADD_NOTIFICATION,
    ACT_CONF_LIST_UPDATE_ASSISTANT,
    ACT_HANDLE_ROLES_REVISION_EVENT,
    ACT_HANDLE_CONTACT_CONNECTION_CHANGE_EVENT,
    ACT_HANDLE_CONTACT_CHANGE_EVENT,
    ACT_HANDLE_HISTORY_CALL_CHANGE_EVENT,
    ACT_HANDLE_HISTORY_CALL_EVENT,
    ACT_SET_USER_PROFILE,
    ACT_HANDLE_FAVOURITES_EVENT,
    ACT_ADD_INCOMMING_CALL,
    ACT_CONFERENCE_HANDLE_API_CONFERENCE_EVENT,
    ACT_PHONE_RTC_CALL_HANDLE_API_CALLS_EVENT,
} from '../actionsTypes'

import {
    MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT,
} from '../mutationsTypes'

import {
    LOGIN,
    CONTACTS,
    CHANNELS,
    VIDEO_CONF,
    PHONE_CNTL,
    ASISTANTS,
    CHATS,
    CLIENTDATA,
    PHONE,
    SOCKET,
    USERDATA,
    ALARM,
    NOTIFICATIONS,
    CALLS,
    USERPROFILE,
} from '../modulesNames'

import {SOCKET_STATUSES} from "../modules/socket"
import event_bus from "../../eventBus"

proto.on('connect', socket => {
    store.commit(`${SOCKET}/${MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT}`)
    store.dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, {})
    store.dispatch('clientdata/setSocketId', socket.io.engine.id)
})

const onDisconnect = (reconnect) => {
    if (reconnect && (!store.getters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] || store.getters[`${USERDATA}/${GET_ROSCHAT_SERVER}`])) {
        store.dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, { msg: i18n.messages[i18n.locale]['no-serv-conn'] })
        store.dispatch(`${SOCKET}/${ACT_SOCKET_RECONNECT}`)
    }
    store.dispatch(`${SOCKET}/${ACT_SET_CONNECTION_STATUS}`, SOCKET_STATUSES.DISCONNECTED)
}

proto.on('disconnect', onDisconnect)

proto.on('connect_error', onDisconnect)

proto.on('qr-login-data-event', data => {
    event_bus.$emit(data)
})

proto.on('tokens-event', (payload) => {
    store.dispatch(`${LOGIN}/${ACT_HANDLE_TOKENS_EVENT}`, payload)
})

proto.on('profile-event', (payload) => {
    store.dispatch(`${USERPROFILE}/${ACT_SET_USER_PROFILE}`, payload)
})

proto.on('favorites-change-event', (payload) => {
    store.dispatch(`${CONTACTS}/${ACT_HANDLE_FAVOURITES_EVENT}`, payload)
})

proto.on('role-revision-change-event', (payload) => {
    store.dispatch('contacts/changeRolesRevisionEvent', payload)
})

proto.on('contact-change-event', ({type, data}) => {
    const {type: eventType, contact, cid} = data
    store.dispatch(`${CONTACTS}/${ACT_HANDLE_CONTACT_CHANGE_EVENT}`, {eventType, contact, cid, type})
});

proto.on('role-revision-change-event', (payload) => {
    store.dispatch(`${CONTACTS}/${ACT_HANDLE_ROLES_REVISION_EVENT}`, payload)
})

proto.on('contact-connection-change-event', (payload) => {
    store.dispatch(`${CONTACTS}/${ACT_HANDLE_CONTACT_CONNECTION_CHANGE_EVENT}`, payload)
})

proto.on('channel-change-event', (payload) => {
    store.dispatch(`${CHANNELS}/${ACT_HANDLE_CHANNEL_CHANGE_EVENT}`, payload)
});

proto.on('publication-event', async data => {
    const { chId, pubId, title } = data
    await store.dispatch('channel/updatePublication', { chId, pubId })
    store.commit('channels/updateChannelLastTitle', { chId, text: title })
    if (store.getters[`${CHANNELS}/${GET_SELECTED_CHANNEL}`] === chId) store.dispatch('channels/watchChannelPublications', { chId })
    store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, { type: 'pub', pub: data })
})

// --------- CONFERENCE OLD ------------------
proto.on('conference-event', (data) => {
    store.dispatch(`${VIDEO_CONF}/${ACT_CONFERENCE_HANDLE_API_CONFERENCE_EVENT}`, data)
})

proto.on('conference-ringing-event', (data) => {
    data.status = 'ringing'
    store.dispatch(`${VIDEO_CONF}/${ACT_CHANGE_CONTACT_STATUS}`, data)
})

proto.on('conference-answer-event', (data) => {
    data.status = 'joined'
    store.dispatch(`${VIDEO_CONF}/${ACT_CHANGE_CONTACT_STATUS}`, data)
})

proto.on('conference-processing-event', (data) => {
    store.dispatch(`${VIDEO_CONF}/${ACT_SAVE_REMOTE_PROCESSING}`, data)
})

proto.on('conference-termination-event', (data) => {
    store.dispatch(`${VIDEO_CONF}/${ACT_TERMINATION_EVENT}`, data)
})

// --------- ASISTANTS ------------------

proto.on('assistant-ta-info-event', (data) => {
    const theme = store.getters['clientdata/getTheme']
    data.theme = theme
    store.dispatch(`${ASISTANTS}/${ACT_UPDATE_TA}`, data)
})
proto.on('assistant-calls-info-event', (data) => {
    store.dispatch(`${ASISTANTS}/${ACT_UPDATE_CALLS}`, data)
})
proto.on('assistant-call-info-event', (data) => {
    store.dispatch(`${ASISTANTS}/${ACT_UPDATE_CALL}`, data)
})
proto.on('assistant-conference-event', (data) => {
    store.dispatch(`${ASISTANTS}/${ACT_CONF_LIST_UPDATE_ASSISTANT}`, data)
})

// -------- CALLS -----------------------

proto.on('rtc-call-event', (data) => {
    store.dispatch(`${PHONE}/${ACT_PHONE_RTC_CALL_HANDLE_API_CALLS_EVENT}`, data)
})
proto.on('rtc-call-termination-event', (data) => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data, eventName: 'rtc-call-termination-event' })
    store.dispatch(`${PHONE_CNTL}/${ACT_HIDE_INCOMMING_CALL}`, { type: 'phone', id: data.callId })
})
proto.on('rtc-call-conference-event', (data) => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, {eventName: 'rtc-call-conference-event', data})
})
proto.on('history-call-event', data => {
    store.dispatch(`${CALLS}/${ACT_HANDLE_HISTORY_CALL_EVENT}`, data)
})
proto.on('history-call-change-event', data => {
    store.dispatch(`${CALLS}/${ACT_HANDLE_HISTORY_CALL_CHANGE_EVENT}`, data)
})
proto.on('rtc-call-ringing-event', data => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-ringing-event' })
})
proto.on('rtc-call-answer-event', data => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-answer-event' })
})
proto.on('rtc-call-processing-event', data => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-processing-event' })
})
proto.on('rtc-call-options-event', data => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data: data, eventName: 'rtc-call-options-event' })
})

// --------- CHATS ------------------

proto.on('chat-change-event', (payload) => {
    store.dispatch(`${CHATS}/${ACT_UPDATE_CHAT}`, payload)
})

proto.on('message-event', (payload) => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_EVENT}`, payload)
})

proto.on('message-change-event', payload => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_CHANGE_EVENT}`, payload)
})

proto.on('message-update-event', payload => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_UPDATE_EVENT}`, payload)
})

proto.on('message-reaction-event', payload => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_REACTION_EVENT}`, payload)
})

proto.on('set-chat-keyboard', payload => {
    store.commit('chats/updateKeyboard', payload)
})