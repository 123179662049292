var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "wrapper-item top no-bottom-line" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("sound-settings.sections")))]),
              _c("custom-drop-down", {
                ref: "select_section",
                attrs: { default: _vm.sections[0], options: _vm.sections },
                on: { "dd-selected-value": _vm.setSection }
              })
            ],
            1
          ),
          _vm.selected_section === _vm.SOUND_TYPES.NOTIFICATION
            ? _c("div", { staticClass: "wrapper-item" }, [
                _c("div", { staticClass: "item-header" }, [
                  _c("span", { staticClass: "short" }, [
                    _vm._v(_vm._s(_vm.$t("volume")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "range-slider" },
                    [
                      _c("i", { staticClass: "fas fa-volume-off" }),
                      _c("vue-slider", {
                        key: "notif",
                        staticClass: "slider",
                        attrs: {
                          disabled: _vm.sliderDisabled,
                          min: "0",
                          max: "100",
                          step: "1"
                        },
                        model: {
                          value: _vm.notificationSliderValue,
                          callback: function($$v) {
                            _vm.notificationSliderValue = $$v
                          },
                          expression: "notificationSliderValue"
                        }
                      }),
                      _c("i", { staticClass: "fas fa-volume-up volume-up" })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "item-content" }, [
                  _c("span", { staticClass: "short" }, [
                    _vm._v(_vm._s(_vm.$t("sound-settings.sound")))
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "audio-toggle-btn round-btn",
                      on: {
                        click: function($event) {
                          return _vm.togglePlaySound(
                            _vm.SOUND_TYPES.NOTIFICATION
                          )
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class: _vm.playNotificationsound
                          ? "fa-pause"
                          : "fa-play"
                      })
                    ]
                  ),
                  _c("div", { staticClass: "item-content sound-file" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        attrs: {
                          title: _vm.getTitle(_vm.SOUND_TYPES.NOTIFICATION)
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectFile(_vm.SOUND_TYPES.NOTIFICATION)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-file-upload" })]
                    ),
                    _vm.updatingSound === _vm.SOUND_TYPES.NOTIFICATION
                      ? _c(
                          "div",
                          { staticClass: "file-name" },
                          [
                            _c("loader"),
                            _c("div", { staticClass: "loader-label" }, [
                              _vm._v(_vm._s(_vm.$t("sound-file-loading")))
                            ])
                          ],
                          1
                        )
                      : _c("div", { staticClass: "file-name" }, [
                          _vm._v(
                            _vm._s(
                              _vm.sounds[_vm.SOUND_TYPES.NOTIFICATION] &&
                                _vm.sounds[_vm.SOUND_TYPES.NOTIFICATION].name
                            )
                          )
                        ]),
                    _vm.notificationSoundSrc !== _vm.DEFAULT_NOTIFICATION_SOUND
                      ? _c(
                          "div",
                          {
                            staticClass: "btn reset",
                            attrs: { title: _vm.$t("reset-initial") },
                            on: {
                              click: function($event) {
                                return _vm.resetSound(
                                  _vm.SOUND_TYPES.NOTIFICATION
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-undo-alt" })]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e(),
          _vm.selected_section === _vm.SOUND_TYPES.RING
            ? _c("div", { staticClass: "wrapper-items" }, [
                _c("div", { staticClass: "wrapper-item" }, [
                  _c("div", { staticClass: "item-content" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("volume")))]),
                    _c(
                      "div",
                      { staticClass: "range-slider" },
                      [
                        _c("i", { staticClass: "fas fa-volume-off" }),
                        _c("vue-slider", {
                          key: "calls",
                          staticClass: "slider",
                          attrs: {
                            disabled: _vm.sliderDisabled,
                            min: "0",
                            max: "100",
                            step: "1"
                          },
                          model: {
                            value: _vm.callSliderValue,
                            callback: function($$v) {
                              _vm.callSliderValue = $$v
                            },
                            expression: "callSliderValue"
                          }
                        }),
                        _c("i", { staticClass: "fas fa-volume-up volume-up" })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "item-content" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("sound-settings.main-call")))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "audio-toggle-btn round-btn",
                        on: {
                          click: function($event) {
                            return _vm.togglePlaySound(_vm.SOUND_TYPES.RING)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.playRingsound ? "fa-pause" : "fa-play"
                        })
                      ]
                    ),
                    _c("div", { staticClass: "item-content sound-file" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          attrs: { title: _vm.getTitle(_vm.SOUND_TYPES.RING) },
                          on: {
                            click: function($event) {
                              return _vm.selectFile(_vm.SOUND_TYPES.RING)
                            }
                          }
                        },
                        [_c("i", { staticClass: "far fa-file-upload" })]
                      ),
                      _vm.updatingSound === _vm.SOUND_TYPES.RING
                        ? _c(
                            "div",
                            { staticClass: "file-name" },
                            [
                              _c("loader"),
                              _c("div", { staticClass: "loader-label" }, [
                                _vm._v(_vm._s(_vm.$t("sound-file-loading")))
                              ])
                            ],
                            1
                          )
                        : _c("div", { staticClass: "file-name" }, [
                            _vm._v(
                              _vm._s(_vm.sounds[_vm.SOUND_TYPES.RING].name)
                            )
                          ]),
                      _vm.ringSoundSrc !== _vm.DEFAULT_RING_SOUND
                        ? _c(
                            "div",
                            {
                              staticClass: "btn reset",
                              attrs: { title: _vm.$t("reset-initial") },
                              on: {
                                click: function($event) {
                                  return _vm.resetSound(_vm.SOUND_TYPES.RING)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-undo-alt" })]
                          )
                        : _vm._e()
                    ])
                  ])
                ]),
                _c("div", { staticClass: "wrapper-item" }, [
                  _c("div", { staticClass: "item-content" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("sound-settings.second-call")))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "audio-toggle-btn round-btn",
                        on: {
                          click: function($event) {
                            return _vm.togglePlaySound(
                              _vm.SOUND_TYPES.SECOND_CALL
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.playSecondcallsound
                            ? "fa-pause"
                            : "fa-play"
                        })
                      ]
                    ),
                    _c("div", { staticClass: "item-content sound-file" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          attrs: {
                            title: _vm.getTitle(_vm.SOUND_TYPES.SECOND_CALL)
                          },
                          on: {
                            click: function($event) {
                              return _vm.selectFile(_vm.SOUND_TYPES.SECOND_CALL)
                            }
                          }
                        },
                        [_c("i", { staticClass: "far fa-file-upload" })]
                      ),
                      _vm.updatingSound === _vm.SOUND_TYPES.SECOND_CALL
                        ? _c(
                            "div",
                            { staticClass: "file-name" },
                            [
                              _c("loader"),
                              _c("div", { staticClass: "loader-label" }, [
                                _vm._v(_vm._s(_vm.$t("sound-file-loading")))
                              ])
                            ],
                            1
                          )
                        : _c("div", { staticClass: "file-name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.sounds[_vm.SOUND_TYPES.SECOND_CALL].name
                              )
                            )
                          ]),
                      _vm.secondCallSoundSrc !== _vm.DEFAULT_SECOND_CALL_SOUND
                        ? _c(
                            "div",
                            {
                              staticClass: "btn reset",
                              attrs: { title: _vm.$t("reset-initial") },
                              on: {
                                click: function($event) {
                                  return _vm.resetSound(
                                    _vm.SOUND_TYPES.SECOND_CALL
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-undo-alt" })]
                          )
                        : _vm._e()
                    ])
                  ])
                ]),
                _c("div", { staticClass: "wrapper-item" }, [
                  _c("div", { staticClass: "item-content" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("sound-settings.hold")))]),
                    _c(
                      "div",
                      {
                        staticClass: "audio-toggle-btn round-btn",
                        on: {
                          click: function($event) {
                            return _vm.togglePlaySound(_vm.SOUND_TYPES.HOLD)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.playHoldsound ? "fa-pause" : "fa-play"
                        })
                      ]
                    ),
                    _c("div", { staticClass: "item-content sound-file" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          attrs: { title: _vm.getTitle(_vm.SOUND_TYPES.HOLD) },
                          on: {
                            click: function($event) {
                              return _vm.selectFile(_vm.SOUND_TYPES.HOLD)
                            }
                          }
                        },
                        [_c("i", { staticClass: "far fa-file-upload" })]
                      ),
                      _vm.updatingSound === _vm.SOUND_TYPES.HOLD
                        ? _c(
                            "div",
                            { staticClass: "file-name" },
                            [
                              _c("loader"),
                              _c("div", { staticClass: "loader-label" }, [
                                _vm._v(_vm._s(_vm.$t("sound-file-loading")))
                              ])
                            ],
                            1
                          )
                        : _c("div", { staticClass: "file-name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.sounds[_vm.SOUND_TYPES.HOLD] &&
                                  _vm.sounds[_vm.SOUND_TYPES.HOLD].name
                              )
                            )
                          ]),
                      _vm.holdSoundSrc !== _vm.DEFAULT_HOLD_SOUND
                        ? _c(
                            "div",
                            {
                              staticClass: "btn reset",
                              attrs: { title: _vm.$t("reset-initial") },
                              on: {
                                click: function($event) {
                                  return _vm.resetSound(_vm.SOUND_TYPES.HOLD)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-undo-alt" })]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm.selected_section === _vm.SOUND_TYPES.RADIO_SOUND
            ? _c("div", { staticClass: "wrapper-item" }, [
                _c("div", { staticClass: "item-header" }, [
                  _c("span", { staticClass: "short" }, [
                    _vm._v(_vm._s(_vm.$t("volume")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "range-slider" },
                    [
                      _c("i", { staticClass: "fas fa-volume-off" }),
                      _c("vue-slider", {
                        key: "radio",
                        staticClass: "slider",
                        attrs: {
                          disabled: _vm.sliderDisabled,
                          min: "0",
                          max: "100",
                          step: "1"
                        },
                        model: {
                          value: _vm.radioSliderValue,
                          callback: function($$v) {
                            _vm.radioSliderValue = $$v
                          },
                          expression: "radioSliderValue"
                        }
                      }),
                      _c("i", { staticClass: "fas fa-volume-up volume-up" })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "item-content" }, [
                  _c("span", { staticClass: "short" }, [
                    _vm._v(_vm._s(_vm.$t("sound-settings.main-call")))
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "audio-toggle-btn round-btn",
                      on: {
                        click: function($event) {
                          return _vm.togglePlaySound(
                            _vm.SOUND_TYPES.RADIO_SOUND
                          )
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class: _vm.playRadiosound ? "fa-pause" : "fa-play"
                      })
                    ]
                  ),
                  _c("div", { staticClass: "item-content sound-file" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        attrs: {
                          title: _vm.getTitle(_vm.SOUND_TYPES.RADIO_SOUND)
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectFile(_vm.SOUND_TYPES.RADIO_SOUND)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-file-upload" })]
                    ),
                    _vm.updatingSound === _vm.SOUND_TYPES.RADIO_SOUND
                      ? _c(
                          "div",
                          { staticClass: "file-name" },
                          [
                            _c("loader"),
                            _c("div", { staticClass: "loader-label" }, [
                              _vm._v(_vm._s(_vm.$t("sound-file-loading")))
                            ])
                          ],
                          1
                        )
                      : _c("div", { staticClass: "file-name" }, [
                          _vm._v(
                            _vm._s(
                              _vm.sounds[_vm.SOUND_TYPES.RADIO_SOUND] &&
                                _vm.sounds[_vm.SOUND_TYPES.RADIO_SOUND].name
                            )
                          )
                        ]),
                    _vm.radioSoundSrc !== _vm.DEFAULT_RADIO_SOUND
                      ? _c(
                          "div",
                          {
                            staticClass: "btn reset",
                            attrs: { title: _vm.$t("reset-initial") },
                            on: {
                              click: function($event) {
                                return _vm.resetSound(
                                  _vm.SOUND_TYPES.RADIO_SOUND
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-undo-alt" })]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                return _vm.modalClose()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        )
      ]),
      _c("audio", {
        ref: _vm.SOUND_TYPES.NOTIFICATION,
        attrs: { src: _vm.notificationSoundSrc },
        on: {
          ended: function($event) {
            return _vm.stopPlay(_vm.SOUND_TYPES.NOTIFICATION)
          }
        }
      }),
      _c("audio", {
        ref: _vm.SOUND_TYPES.RING,
        attrs: { src: _vm.ringSoundSrc },
        on: {
          ended: function($event) {
            return _vm.stopPlay(_vm.SOUND_TYPES.RING)
          }
        }
      }),
      _c("audio", {
        ref: _vm.SOUND_TYPES.SECOND_CALL,
        attrs: { src: _vm.secondCallSoundSrc },
        on: {
          ended: function($event) {
            return _vm.stopPlay(_vm.SOUND_TYPES.SECOND_CALL)
          }
        }
      }),
      _c("audio", {
        ref: _vm.SOUND_TYPES.HOLD,
        attrs: { src: _vm.holdSoundSrc },
        on: {
          ended: function($event) {
            return _vm.stopPlay(_vm.SOUND_TYPES.HOLD)
          }
        }
      }),
      _c("audio", {
        ref: _vm.SOUND_TYPES.RADIO_SOUND,
        attrs: { src: _vm.radioSoundSrc },
        on: {
          ended: function($event) {
            return _vm.stopPlay(_vm.SOUND_TYPES.RADIO_SOUND)
          }
        }
      }),
      _c("input", {
        ref: "fileLoader",
        staticStyle: { display: "none" },
        attrs: { type: "file" },
        on: { change: _vm.uploadFile }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }